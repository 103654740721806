/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../../shared/store/data-Slice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CloseBlack, ComplaintDetail, Delete, GroupClose, PerDetails, SecondaryArr, Warn } from "../../../common/svg/AllSvg";
import { components } from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PrimaryTextarea from "../../../common/form/PrimaryTextarea";
import SelectSecondaryComponent from "../../../common/form/SelectSecondaryComponent";
import PrimaryInput from "../../../common/form/PrimaryInput";
import SecondaryBtnLight from "../../../common/form/SecondaryBtnLight";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import { url } from "../../../../shared/utils/url";
import useAxios from "../../../../shared/hooks/use-axios";
import { numberOnPaste } from "../../../../shared/utils/numberOnPaste";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";
import AadharDrivingAttachment from "./AadharDrivingAttachment";

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <SecondaryArr />
            </components.DropdownIndicator>
        )
    );
};

const NewComplaint = () => {
    const { t } = useTranslation('common');
    const createComplaint: boolean = useSelector((state: any) => state.data.createComplaint);
    const tenant: string = useSelector((state: any) => state.data.tenant);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const user: any = useSelector((state: any) => state.auth.user);
    const [submit, setSubmit] = useState<boolean>(false)
    const [step, setStep] = useState<number>(0)
    const [files, setFiles] = useState<any>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [disable, setDisable] = useState<boolean>(false);
    const [err, setErr] = useState<any>("");
    const [files1, setFiles1] = useState<any>([]);
    const [uploadProgress1, setUploadProgress1] = useState<number>(0);
    const [disable1, setDisable1] = useState<boolean>(false);
    const [err1, setErr1] = useState<any>("");
    const [stateList, setStateList] = useState<any[]>([])
    const [cfmOpen, setCfmOpen] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();
    const ref1: any = useRef();
    const steps: any[] = [
        { name: "Personal Details", icon: PerDetails },
        { name: "Complaint & Submit", icon: ComplaintDetail }
    ]
    const genderList: any[] = [{ label: t("recruitment.profile.genders.male"), value: "male" }, { label: t("recruitment.profile.genders.female"), value: "female" }, { label: t("recruitment.profile.genders.other"), value: "other" }]
    const choice: any[] = [{ label: t("recruitment.hr.candidate_detail.yes"), value: true }, { label: t("recruitment.hr.candidate_detail.no"), value: false }]

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: (user?.role === "Vigilance" ? '' : (!!user?.first_name ? user?.first_name : '')),
            last_name: (user?.role === "Vigilance" ? '' : (!!user?.last_name ? user?.last_name : '')),
            age: '',
            gender: '',
            aadhaar_number: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            email: (user?.role === "Vigilance" ? '' : (!!user?.email ? user?.email : '')),
            phone: (user?.role === "Vigilance" ? '' : (!!user?.phone ? user?.phone : '')),
            alt_mobile_no: '',
            subject: '',
            description: '',
        },
        validationSchema: step === 0 ? yup.object().shape({
            first_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.first_name")),
            last_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.last_name")),
            age: yup.number().required(t("grievance.new_complaint.validation.age")),
            address: yup.string().required(t("grievance.new_complaint.validation.address")),
            gender: yup.object().required(t("grievance.new_complaint.validation.gender")),
            city: yup.string().required(t("grievance.new_complaint.validation.city")),
            state: yup.object().required(t("grievance.new_complaint.validation.state")),
            zipcode: yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, t("grievance.new_complaint.validation.valid_zipcode")).required(t("grievance.new_complaint.validation.zipcode")),
            phone: yup.number().test('len', t("grievance.new_complaint.validation.valid_phone"), (val: any) => val && val.toString().length === 10).required(t("grievance.new_complaint.validation.phone")),
            alt_mobile_no: yup.number().nullable().test('len', t("grievance.new_complaint.validation.valid_alt_phone"), (val: any) => {
                if (val) {
                    return val?.toString()?.length === 10;
                }
                return true;
            }),
            email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
            aadhaar_number: yup.string()
                .test("is-valid", t("grievance.new_complaint.validation.valid_aadhaar_driving_number"), (value: any) => {
                    if (!value) return false;

                    const aadharRegex = /^\d{12}$/; // 12 digits for Aadhar
                    const dlRegex = /^[A-Z]{2}-\d{2}-\d{13}$/; // DL format: AA-00-0000000000000

                    return aadharRegex.test(value) || dlRegex.test(value);
                })
                .required(t("grievance.new_complaint.validation.aadhaar_driving_number")),
        }) :
            yup.object().shape({
                description: yup.string().max(512, t("grievance.new_complaint.validation.description_len")).required(t("grievance.new_complaint.validation.description")),
                subject: yup.string().max(512, t("grievance.new_complaint.validation.subject_len")).required(t("grievance.new_complaint.validation.subject")),
            })
        // step === 0 ? (user?.role === "Vigilance" ?
        //     yup.object().shape({
        //         address: yup.string(),
        //         city: yup.string(),
        //         state: yup.object(),
        //         zipcode: yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, t("grievance.new_complaint.validation.valid_zipcode")),
        //         first_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")).required(t("auth.form.validation.first_name")),
        //         last_name: yup.string().matches(/^[A-Za-z\s]+$/, t("contact_us.form.alpha_char_err")),
        //         phone: yup.number().test('len', t("grievance.new_complaint.validation.valid_phone"), (val: any) => val && val.toString().length === 10).required(t("grievance.new_complaint.validation.phone")),
        //         email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
        //         pan_number: yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, t("grievance.new_complaint.validation.valid_pan_number")).required(t("grievance.new_complaint.validation.pan_number")),
        //         aadhaar_number: yup.string().matches(/^[0-9]{12}$/, t("grievance.new_complaint.validation.valid_aadhaar_number")).required(t("grievance.new_complaint.validation.aadhaar_number"))
        //     })
        //     : yup.object().shape({
        //         address: yup.string(),
        //         city: yup.string(),
        //         state: yup.object(),
        //         zipcode: yup.string().matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, t("grievance.new_complaint.validation.valid_zipcode")),
        //         phone: yup.number().test('len', t("grievance.new_complaint.validation.valid_phone"), (val: any) => val && val.toString().length === 10).required(t("grievance.new_complaint.validation.phone")),
        //         email: yup.string().email(t("auth.form.validation.wrong_email")).required(t("auth.form.validation.email")),
        //         pan_number: yup.string().matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, t("grievance.new_complaint.validation.valid_pan_number")).required(t("grievance.new_complaint.validation.pan_number")),
        //         aadhaar_number: yup.string().matches(/^[0-9]{12}$/, t("grievance.new_complaint.validation.valid_aadhaar_number")).required(t("grievance.new_complaint.validation.aadhaar_number"))
        //     })) :
        //     yup.object().shape({
        //         description: yup.string().max(512, t("grievance.new_complaint.validation.description_len")).required(t("grievance.new_complaint.validation.description")),
        //         subject: yup.string().max(512, t("grievance.new_complaint.validation.subject_len")).required(t("grievance.new_complaint.validation.subject")),
        //     })
        ,
        onSubmit: async (values: any) => {
            //submit
            if (step === 0) {
                if (files1?.length === 0) {
                    setErr1(t("grievance.new_complaint.field.aadhaar_driving_attachment_err"));
                    return true;
                }

                setStep(1)
            } else {
                try {
                    setSubmit(true)

                    const formData = new FormData();
                    formData.append("subject", values?.subject);
                    formData.append("description", values?.description);
                    formData.append("phone_number", values?.phone);
                    formData.append("alt_phone_number", values?.alt_mobile_no);
                    formData.append("address", values?.address);
                    formData.append("city", values?.city);
                    formData.append("state", values?.state?.value);
                    formData.append("pincode", values?.zipcode);
                    formData.append("first_name", values?.first_name);
                    formData.append("last_name", values?.last_name);
                    formData.append("email", values?.email);
                    formData.append("age", values?.age);
                    formData.append("gender", values?.gender?.value);
                    formData.append("aadhaar_number", values?.aadhaar_number);
                    formData.append("status", "Pending_Review");
                    files?.length > 0 && formData.append("attachment", files?.[0]);
                    formData.append("aadhaar_proof", files1?.[0]);

                    const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + (user?.role === "Vigilance" ? url?.admin_create_ticket : url?.create_ticket)}`, formData);

                    if (res && res?.status === 201) {
                        dispatch(dataActions.setCreateComplaint(false));
                        dispatch(dataActions.setComplaintApiCall(true));
                        !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                    }
                } catch (error) {
                    errors(error)
                    setSubmit(false)
                    console.error("error while create complaint", error);
                }
            }
        }
    });

    useEffect(() => {
        const getExistUser = async () => {
            try {
                const res: any = await useAxios.post(`/${(!!lang ? lang : "en") + url?.get_exist_user}`, { mobile: formik?.values?.phone });

                if (res && res.status === 200) {
                    !!res?.data?.first_name && formik.setFieldValue("first_name", res?.data?.first_name)
                    !!res?.data?.last_name && formik.setFieldValue("last_name", res?.data?.last_name)
                    !!res?.data?.email && formik.setFieldValue("email", res?.data?.email)
                    !!res?.data?.profile?.age && formik.setFieldValue("age", res?.data?.profile?.age)
                    !!res?.data?.profile?.gender && formik.setFieldValue("gender", genderList?.find((item: any) => item?.value === res?.data?.profile?.gender))
                }
            } catch (error) {
                console.error("error while fetching user details", error);
            }
        }

        if (formik?.values?.phone?.toString()?.length === 10) {
            getExistUser()
        }
    }, [formik?.values?.phone])

    useEffect(() => {
        if (Object?.keys(formik?.errors)?.length === 0) {
            formik.setTouched({});
        }
    }, [formik?.errors, step])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && !cfmOpen) {
                dispatch(dataActions.setCreateComplaint(false));
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, cfmOpen]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target)) {
                setCfmOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            if (cfmOpen) {
                setCfmOpen(false);
            } else {
                dispatch(dataActions.setCreateComplaint(false))
                !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
            }
        }
    };

    const simulateChunkUpload = (chunk: any) => {
        return new Promise((resolve: any, reject: any) => {
            setTimeout(() => {
                resolve();
            }, 1000);
        });
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            setErr("")
            if (!acceptedFiles[0]?.name?.match(/\.(PDF|pdf|Doc|doc|docx|DOCX|png|PNG|jpg|JPG|jpeg|JPEG)$/)) {
                setErr(t("passenger.lost_found.report_form.validation.valid_file"));
                return true;
            }

            if (acceptedFiles[0].size > (5 * 1000 * 1024)) {
                setErr(t("passenger.lost_found.report_form.validation.size_err"));
                return;
            }
            setUploadProgress(0);
            setDisable(true)
            setFiles([acceptedFiles[0]])
            setErr("")

            let uploadFile = acceptedFiles[0];
            if (uploadFile) {
                const chunkSize = 1024 * 1024; // 1MB chunk size
                const totalChunks = Math.ceil(uploadFile.size / chunkSize);
                let currentChunk = 0;

                const uploadNextChunk = () => {
                    const start = currentChunk * chunkSize;
                    const end = Math.min(start + chunkSize, uploadFile.size);
                    const chunk = uploadFile.slice(start, end);

                    simulateChunkUpload(chunk)
                        .then(() => {
                            currentChunk++;

                            if (currentChunk < totalChunks) {
                                const progress = Math.floor((currentChunk / totalChunks) * 100);
                                setUploadProgress(progress);

                                uploadNextChunk();
                            } else {
                                setUploadProgress(100);
                                setDisable(false)
                            }
                        })
                        .catch((error) => {
                            console.error('Error uploading chunk', error);
                        });
                };

                uploadNextChunk();
            }
        }
    });

    const removeFile = (idx: number) => {
        let arr: any[] = [];
        for (let i = 0; i < files?.length; i++) {
            const el = files[i];

            if (idx !== i) {
                arr.push(el)
            }
        }

        setFiles(arr)
    };

    const thumbs = files?.map((file: any, idx: number) => (
        <div key={idx} className='w-full flex items-center justify-between px-[15px] xlx:px-[19px] border border-[#D7D8DD] rounded-full bg-[#F5F5F5] h-[35px] xlx:h-[40px] gap-[15px]'>
            <Link to={!!file?.url ? file?.url : URL.createObjectURL(file)} download={!!file?.url ? file?.url : URL.createObjectURL(file)} target="_blank" className="text-blackDull text-sm leading-snug font-normal truncate hover:underline focus:underline">{file?.name}</Link>

            {(uploadProgress !== 100 && (idx + 1 === files?.length)) ?
                <div className="flex items-center gap-[5px]">
                    <h1 className="text-blackDull text-sm leading-snug font-normal">{t("passenger.lost_found.report_form.form.uploading")} {uploadProgress}%</h1>
                    <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]" onClick={() => removeFile(idx)} type="button">
                        <GroupClose />
                    </button>
                </div> :
                <div className="flex items-center gap-[5px] whitespace-nowrap">
                    <div {...getRootProps()} onClick={(e) => e.stopPropagation}>
                        <input {...getInputProps()} />
                        <button type="button" onClick={open} className="text-blackDull text-sm leading-snug font-semibold disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable}> {t("passenger.lost_found.report_form.form.replace")}</button>
                    </div>
                    <div className="border-r h-[16px] border-primary pl-[5px]"></div>
                    <button aria-label={t("aria_label.delete")} title={t("aria_label.delete")} onClick={() => removeFile(idx)} type="button" className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD]">
                        <Delete />
                    </button>
                </div>
            }
        </div>
    ));

    const getState = async () => {
        try {
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.state}`);

            if (res && res.status === 200) {
                res?.data?.results?.length > 0 && setStateList(res?.data?.results?.map((item: any) => ({ label: item?.text, value: item?.id })))

                if (!!formik?.values?.state) {
                    let obj: any = res?.data?.results?.find((item: any) => formik?.values?.state?.value === item?.id)
                    formik.setFieldValue("state", obj)
                }
            }
        } catch (error) {
            console.error("error while fetching state", error);
        }
    }

    useEffect(() => {
        if (!!lang) {
            getState();
        }
    }, [lang])

    const handleNavigate = async () => {
        const protocol = window.location.protocol;
        const www = window.location.host?.includes("www.") ? true : false;
        const host = www ? window.location.host?.replace("www.", "") : window.location.host;
        let currentHost: any = !!tenant ? host?.replace(`${tenant}.`, "") : host;
        const newUrl: any = tenant === url?.subdomain?.grievance ? `${protocol}//${currentHost}` : `${protocol}//${url?.subdomain?.grievance}.${currentHost}`;
        if (params?.lang) {
            window.open(`${newUrl}/${params?.lang}`, "_blank");
        } else {
            window.open(newUrl, "_blank");
        }
        setCfmOpen(false)
    }

    return (
        <>
            {createComplaint &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] xlx:w-[740px] min-h-[550px] xlx:min-h-[570px] h-full relative bg-white rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)] pb-[91px] xlx:pb-[96px]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex justify-between gap-[15px] border-b border-[#DADADA] border-opacity-[0.5] px-[20px] xlx:px-[30px] py-[15px] xlx:py-[20px]">
                                <div className="flex flex-col xlx:gap-[2px]">
                                    <h3 className="text-primaryText font-bold">{t("grievance.new_complaint.title")}</h3>
                                    <h6 className="text-blackDull font-medium">{t("grievance.new_complaint.desc")}</h6>
                                </div>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e]" type="button" autoFocus={true} onClick={() => {
                                    dispatch(dataActions.setCreateComplaint(false))
                                    !!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <div className="border-b border-[#DADADA] border-opacity-[0.5] w-full flex items-center justify-center p-[15px] bg-[#DCFCFF] bg-opacity-[0.5] gap-[15px] xlx:gap-[30px]">
                                {Array.isArray(steps) && steps?.map((item: any, index: number) => (
                                    <button type="button" className={`${index === step ? "" : "text-opacity-[0.5]"} flex items-center gap-[9px] font-semibold text-[#303030] text-sm`} key={index}>
                                        <div className={`${step === index ? "bg-white border-[#02C0FC] border" : "bg-[#D9D9D9] bg-opacity-[0.7] border-[#D9D9D9] border border-opacity-[0.7]"} min-w-[30px] h-[30px] rounded-full flex items-center justify-center`}>
                                            <item.icon className={`${step === index ? "fill-[#02C0FC]" : "fill-[#1C1B1F] opacity-[0.7]"}`} />
                                        </div>
                                        {item?.name}
                                    </button>
                                ))}
                            </div>

                            <form onSubmit={(e: any) => { e.preventDefault(); formik?.handleSubmit(); }} className="px-[30px] xlx:px-[60px] py-[28px] flex flex-col gap-[25px] xlx:gap-[30px]">
                                {step === 0 && <>
                                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("recruitment.profile.field.first_name")}*`} name="first_name" type="text" placeholder={t("recruitment.profile.field.first_name")} value={formik?.values?.first_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.first_name && formik?.errors?.first_name && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.first_name}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("recruitment.profile.field.last_name")}*`} name="last_name" type="text" placeholder={t("recruitment.profile.field.last_name")} value={formik?.values?.last_name} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.last_name && formik?.errors?.last_name && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger dark:text-darkDanger text-xs font-medium">{formik?.errors?.last_name}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark font-medium">{`${t("grievance.new_complaint.field.gender")}*`}</h6>
                                            <SelectSecondaryComponent
                                                options={genderList}
                                                name="gender"
                                                placeholder={t("grievance.new_complaint.field.select_gender")}
                                                isSearchable={false}
                                                arrow={true}
                                                components={{ DropdownIndicator }}
                                                onChange={(e: any) => formik.setFieldValue("gender", e)}
                                                onBlur={(e: any) => formik.setFieldTouched("gender", e)}
                                                value={formik.values.gender}
                                            />
                                            {formik?.touched?.gender && formik?.errors?.gender && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.gender}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("grievance.new_complaint.field.age")}*`} name="age" type="number" placeholder={t("grievance.new_complaint.field.age")} value={formik?.values?.age} onBlur={formik?.handleBlur} onChange={formik?.handleChange} exceptThisSymbols={["e", "E", "+", "-", "."]} />
                                            {formik?.touched?.age && formik?.errors?.age && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.age}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark font-medium">{t("grievance.new_complaint.field.phone")}*</h6>
                                            <div className="flex items-center gap-[10px] w-full">
                                                <input className="flex items-center justify-center px-[7px] w-[35px] xlx:w-[40px] h-[35px] xlx:h-[40px] rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] text-blackDull text-sm leading-snug" defaultValue="+91" disabled autoComplete="off" />
                                                <input className="placeholder:text-blackDull w-full rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug disabled:bg-[#F5F5F5] disabled:opacity-[0.7] disabled:dark:bg-bgDark" type="number" name="phone" placeholder={t("grievance.new_complaint.field.phone")} autoComplete="off" value={formik?.values?.phone} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                                                    onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onPaste={(e: any) => numberOnPaste(e)} />
                                            </div>
                                            {formik?.touched?.phone && formik?.errors?.phone && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.phone}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{t("grievance.new_complaint.field.alt_mobile_no")}</h6>
                                            <div className="flex items-center gap-[10px] w-full">
                                                <input className="flex items-center justify-center px-[7px] w-[35px] xlx:w-[40px] h-[35px] xlx:h-[40px] rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull disabled:hover:bg-[#F5F5F5] disabled:dark:hover:bg-bgDark disabled:opacity-[0.7]" defaultValue="+91" disabled autoComplete="off" />
                                                <input className="placeholder:text-blackDull w-full rounded-[6px] hover:bg-white focus:bg-white  bg-[#F5F5F5] border border-[#D7D8DD] h-[35px] xlx:h-[40px] px-[15px] xlx:px-[17px] text-blackDull text-sm leading-snug dark:bg-bgDark dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull dark:border-[#454545] dark:text-textBlackDull placeholder:dark:text-textBlackDull disabled:hover:bg-[#F5F5F5] disabled:dark:hover:bg-bgDark" type="number" name="alt_mobile_no" placeholder={t("grievance.new_complaint.field.alt_mobile_no")} autoComplete="off" value={formik?.values?.alt_mobile_no} onBlur={formik?.handleBlur} onChange={formik?.handleChange} onFocus={(e: any) => e.target.addEventListener("wheel", function (e: any) { e.preventDefault() }, { passive: false })}
                                                    onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} onPaste={(e: any) => numberOnPaste(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("auth.form.field.email")}*`} name="email" type="email" placeholder={t("auth.form.field.email")} value={formik?.values?.email} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.email && formik?.errors?.email && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.email}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("grievance.new_complaint.field.aadhaar_driving_number")}*`} name="aadhaar_number" type="text" placeholder={t("grievance.new_complaint.field.aadhaar_driving_number")} value={formik?.values?.aadhaar_number} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.aadhaar_number && formik?.errors?.aadhaar_number && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.aadhaar_number}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-[4px]">
                                        <PrimaryTextarea title={`${t("grievance.new_complaint.field.address")}*`} placeholder={t("grievance.new_complaint.field.enter_address")} name="address" rows="4" value={formik?.values?.address} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                        {formik?.touched?.address && formik?.errors?.address && <div className="flex gap-[6px] items-start"><Warn />
                                            <span className="text-danger text-xs font-medium">{formik?.errors?.address}</span>
                                        </div>}
                                    </div>

                                    <div className="grid w-full grid-cols-1 md:grid-cols-3 gap-[15px] xlx:gap-[20px]">
                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("grievance.new_complaint.field.city")}*`} type="text" name="city" placeholder={t("grievance.new_complaint.field.city")} value={formik?.values?.city} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.city && formik?.errors?.city && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.city}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark font-medium">{`${t("grievance.new_complaint.field.state")}*`}</h6>
                                            <SelectSecondaryComponent
                                                options={stateList}
                                                name="state"
                                                placeholder={t("grievance.new_complaint.field.select_state")}
                                                isSearchable={false}
                                                arrow={true}
                                                components={{ DropdownIndicator }}
                                                onChange={(e: any) => formik.setFieldValue("state", e)}
                                                onBlur={(e: any) => formik.setFieldTouched("state", e)}
                                                value={formik.values.state}
                                            />
                                            {formik?.touched?.state && formik?.errors?.state && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.state}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryInput title={`${t("grievance.new_complaint.field.zipcode")}*`} type="number" name="zipcode" placeholder={t("grievance.new_complaint.field.zipcode")} value={formik?.values?.zipcode} onBlur={formik?.handleBlur} onChange={formik?.handleChange} exceptThisSymbols={["e", "E", "+", "-", "."]} />
                                            {formik?.touched?.zipcode && formik?.errors?.zipcode && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.zipcode}</span>
                                            </div>}
                                        </div>
                                    </div>

                                    <AadharDrivingAttachment files1={files1} setFiles1={setFiles1} uploadProgress1={uploadProgress1} setUploadProgress1={setUploadProgress1} disable1={disable1} setDisable1={setDisable1} err1={err1} setErr1={setErr1} simulateChunkUpload={simulateChunkUpload} submit={submit} />

                                    <div className="flex w-full items-end justify-end absolute bottom-0 left-0 right-0 px-[30px] xlx:px-[60px] py-[28px]">
                                        <SecondaryBtn title={t("passenger.lost_found.report_form.form.next")} disabled={submit || disable || disable1} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                    </div>
                                </>}

                                {step === 1 &&
                                    <>
                                        <div className="flex flex-col gap-[4px]">
                                            <h6 className="text-blackDark dark:text-textBlackDark font-medium">{`${t("grievance.new_complaint.field.suspected")}`}</h6>
                                            <div className="flex items-center gap-[10px]">
                                                {Array.isArray(choice) && choice?.map((item: any, index: number) => (
                                                    <button type="button" key={index} className={`${(choice?.[0]?.value === item?.value) ? "border border-primary bg-primary text-white focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e]" : "border border-blackDull bg-white text-blackDull focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]"} rounded-[10px] h-[35px] xlx:h-[40px] px-[12px] text-sm leading-snug font-normal disabled:shadow-none disabled:dark:shadow-none disabled:opacity-[0.7]`} onClick={() => choice?.[1]?.value === item?.value && setCfmOpen(true)}>{item?.label}</button>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryTextarea title={`${t("grievance.new_complaint.field.subject")}*`} placeholder={t("grievance.new_complaint.field.subject_placeholder")} name="subject" rows="4" value={formik?.values?.subject} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.subject && formik?.errors?.subject && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.subject}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px]">
                                            <PrimaryTextarea title={`${t("grievance.new_complaint.field.description")}*`} rows="4" name="description" placeholder={t("grievance.new_complaint.field.start_tying")} value={formik?.values?.description} onBlur={formik?.handleBlur} onChange={formik?.handleChange} />
                                            {formik?.touched?.description && formik?.errors?.description && <div className="flex gap-[6px] items-start"><Warn />
                                                <span className="text-danger text-xs font-medium">{formik?.errors?.description}</span>
                                            </div>}
                                        </div>

                                        <div className="flex flex-col gap-[4px] w-full">
                                            <h6 className="text-blackDark font-medium">{t("grievance.new_complaint.field.add_attachment")}</h6>

                                            {files?.length === 0 && <div className="flex flex-col gap-[4px]">
                                                <div className="h-[35px] xlx:h-[40px] w-full rounded-full border border-[#D7D8DD] bg-[#F5F5F5] flex items-center justify-between pl-[5px] pr-[18px] xlx:pr-[22px]">
                                                    <div {...getRootProps()} onClick={(e) => e.stopPropagation} className={`rounded-full ${(disable || files?.length === 1 || submit) ? "" : "focus:shadow-[0_0_0_3px_#059db25e] hover:shadow-[0_0_0_3px_#059db25e]"}`}>
                                                        <input {...getInputProps()} />
                                                        <button type="button" tabIndex={-1} onClick={open} className="h-[25px] xlx:h-[30px] flex items-center justify-center w-fit px-[15px] xlx:px-[19px] text-white font-bold text-sm !leading-tight rounded-full bg-primary disabled:opacity-[0.5] disabled:cursor-not-allowed" disabled={disable || files?.length === 1 || submit}> {t("passenger.lost_found.report_form.form.upload_file")}</button>
                                                    </div>

                                                    <h6 className="text-blackDull font-normal !leading-tight">{t("grievance.new_complaint.field.type")} <span className="text-primaryText font-semibold !leading-tight"> | {t("passenger.lost_found.report_form.form.size")}</span></h6>
                                                </div>
                                                {!!err && <div className="flex gap-[6px] mt-[4px]"><Warn />
                                                    <span className="text-danger text-xs font-medium">{err}</span>
                                                </div>}
                                            </div>}
                                            {files?.length > 0 && <aside className='w-full flex flex-col gap-[12px]'>{thumbs}</aside>}
                                        </div>


                                        <div className="flex w-full items-end justify-between absolute bottom-0 left-0 right-0 px-[30px] xlx:px-[60px] py-[28px]">
                                            <SecondaryBtnLight type="button" title={t("grievance.new_complaint.field.go_back")} css="px-[30px] xlx:px-[40px] font-semibold rounded-[6px] text-sm leading-snug" onClick={() => setStep(0)} />
                                            <SecondaryBtn title={t("contact_us.form.submit")} disabled={submit || disable || disable1} onClick={formik.handleSubmit} css="w-fit px-[30px] xlx:px-[40px] flex items-center justify-center !rounded-[6px] font-bold uppercase" />
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div >}

            {cfmOpen &&
                <div className="fixed inset-0 z-[36] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] h-auto w-full sm:w-[380px] relative shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)] px-[20px] py-[15px]" ref={ref1}>
                            <div className="flex w-full items-end justify-end absolute top-[15px] right-[20px]">
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" autoFocus onClick={() => setCfmOpen(false)}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <div className="flex flex-col items-center justify-end gap-[25px] h-full pt-[40px]">
                                <h4 className="max-w-[350px] text-center font-semibold text-blackDark dark:text-textBlackDark">{t("grievance.new_complaint.field.cfm_suspected")}</h4>
                                <div className="w-full flex items-center justify-center gap-[10px]">
                                    <SecondaryBtn type="button" title={t("recruitment.profile.field.btn.ok")} css="px-[20px] font-semibold rounded-[6px] text-sm leading-snug w-fit uppercase" onClick={handleNavigate} disabled={submit} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default NewComplaint;