/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../../shared/store/data-Slice";
import { ActivityLogo, Clock, CloseBlack, DrpArr, EditCategory, LinkIcon, TableSearch, Warn } from "../../../common/svg/AllSvg";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { url } from "../../../../shared/utils/url";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../common/layout/PageLoader";
import moment from "moment";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import Error from "../../../common/form/Error";
import SecondaryBtnLight from "../../../common/form/SecondaryBtnLight";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";

const ComplaintDetail = ({ type }: any) => {
    const { t } = useTranslation('common');
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [stFocus, setStFocus] = useState<boolean>(false)
    const [changeCategory, setChangeCategory] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [searchList, setSearchList] = useState<any[]>([]);
    const [categoryLoading, setCategoryLoading] = useState<boolean>(true);
    const [focus, setFocus] = useState<boolean>(false);
    const [changeCategoryCfm, setChangeCategoryCfm] = useState<boolean>(false);
    const [selectCategory, setSelectCategory] = useState<any>(null);
    const [submit, setSubmit] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();
    const ref1: any = useRef();
    const ref2: any = useRef();
    const menus: any[] = user?.role === "grievance_supervisor_admin" ? [
        { name: t("grievance.home.banner.status.pending"), key: "Pending_Review" },
        { name: t("grievance.home.banner.status.in_progress"), key: "Pending_Verification" },
        { name: t("grievance.home.banner.status.in_investigation"), key: "Under_Investigation" },
        { name: t("grievance.home.banner.status.refer_back"), key: "Refer_To_Customer" },
        { name: t("grievance.home.banner.status.resolved"), key: "Resolved" }
    ] : [
        { name: t("grievance.home.banner.status.pending"), key: "Pending_Review" },
        { name: t("grievance.home.banner.status.in_progress"), key: "Pending_Verification" },
        { name: t("grievance.home.banner.status.in_investigation"), key: "Under_Investigation" },
        { name: t("grievance.home.banner.status.refer_back"), key: "Refer_To_Customer" }
    ]

    const statusList: any = { "Pending_Review": t("grievance.home.banner.status.pending"), "Pending_Verification": t("grievance.home.banner.status.in_progress"), "Under_Investigation": t("grievance.home.banner.status.in_investigation"), "Refer_To_Customer": t("grievance.home.banner.status.refer_back"), "Resolved": t("grievance.home.banner.status.resolved") }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && !changeCategory && !changeCategoryCfm) {
                dispatch(dataActions.setComplaintDetail(false));
                if (window?.location?.pathname?.includes("resolve")) {
                    !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                } else {
                    type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/grievance-complaints`) : navigate(`/grievance-complaints`))
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, changeCategory, changeCategoryCfm]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref1.current && !ref1.current.contains(event.target) && !changeCategoryCfm) {
                setChangeCategory(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref1, changeCategoryCfm]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref2.current && !ref2.current.contains(event.target)) {
                setChangeCategoryCfm(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref2]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            if (changeCategory) {
                setChangeCategoryCfm(false)
            } else {
                dispatch(dataActions.setComplaintDetail(false))
                if (window?.location?.pathname?.includes("resolve")) {
                    !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                } else {
                    type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/grievance-complaints`) : navigate(`/grievance-complaints`))
                }
            }
        }
    };

    const getComplaint = async () => {
        try {
            setLoading(true)
            setIsError(false)
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.complaint_details + params?.id}/`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setData(res?.data?.[0])
                setLoading(false)
            }
        } catch (error) {
            setIsError(true)
            setLoading(false)
            console.error("error while fetching complaint detail", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getComplaint();
    }, [lang, params?.id])

    const handleStatusChange = async (key: any) => {
        try {
            if (key === "Resolved") {
                dispatch(dataActions.setComplaintDetail(false))
                !!params?.lang ? navigate(`/${params?.lang}/resolve-complaint/${params?.id}`) : navigate(`/resolve-complaint/${params?.id}`)
            } else if (key === "Refer_To_Customer") {
                dispatch(dataActions.setComplaintDetail(false))
                !!params?.lang ? navigate(`/${params?.lang}/refer-back-complaint/${params?.id}`) : navigate(`/refer-back-complaint/${params?.id}`)
            } else {
                const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.assign_complaint + data?.id}/`, { status: key, activity_remarks: null });

                if (res && res.status === 200) {
                    dispatch(dataActions.setComplaintApiCall(true));
                    getComplaint()
                }
            }
        } catch (error) {
            errors(error);
            console.error("error while status change", error);
        }
    }

    const getCategory = async () => {
        try {
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.complaint_category}`);

            if (res && res.status === 200 && res?.data?.results?.length > 0) {
                const arr: any[] = res?.data?.results?.map((item: any) => ({ label: item?.name, value: item?.id }));
                setCategoryList(arr)
                setSearchList(arr)
                setCategoryLoading(false)
            }
        } catch (error) {
            setCategoryLoading(false)
            console.error("error while fetching category", error);
        }
    }

    useEffect(() => {
        !!lang && getCategory();
    }, [lang])

    useEffect(() => {
        if (!!data?.category && categoryList?.length > 0) {
            let obj: any = categoryList?.find((item: any) => item?.value === data?.category)
            setSelectCategory(obj)
        }
    }, [categoryList, data])

    const handleSearch = () => {
        let arr: any[] = searchList;
        if (!!search) {
            // eslint-disable-next-line 
            arr = arr.filter((item: any) => {
                if (item?.label?.toLowerCase()?.includes(search?.toLowerCase())) {
                    return true;
                }
            });
        } else {
            arr = searchList;
        }

        setCategoryList(arr);
    }

    useEffect(() => {
        handleSearch();
    }, [search])

    const handleChangeCategory = async () => {
        try {
            setSubmit(true)
            const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.change_category + data?.id}/`, { category: selectCategory?.value });

            if (res && res.status === 200) {
                setChangeCategoryCfm(false);
                setChangeCategory(false);
                setSubmit(false);
                getComplaint();
            }
        } catch (error) {
            setSubmit(false);
            errors(error);
            console.error("error while change category", error);
        }
    }

    return (
        <>
            {complaintDetail &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[700px] msm:w-[930px] min-h-[550px] xlx:min-h-[583px] relative bg-white dark:bg-bgDarkDull rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="w-full flex justify-between gap-[15px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText dark:text-white font-semibold">{data?.complaint_no}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => {
                                    dispatch(dataActions.setComplaintDetail(false))
                                    if (window?.location?.pathname?.includes("resolve")) {
                                        !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                                    } else {
                                        type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/grievance-complaints`) : navigate(`/grievance-complaints`))
                                    }
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>
                            {loading ?
                                <PageLoader />
                                : <>
                                    {isError ? <Error /> : <div className="h-full w-full py-[15px] xlx:py-[19px] px-[20px] xlx:px-[24px] flex gap-[15px] xlx:gap-[25px] flex-col xlsm:flex-row">
                                        <div className="w-full xlsm:w-[195px] bg-opacity-[0.2] bg-[#E4E4E4] rounded-[15px] border border-[#A7A7A7] h-full border-opacity-[0.2] flex flex-col pb-[130px] xlx:pb-[140px]">
                                            <h6 className="flex items-center justify-center w-full text-[#313131] dark:text-white font-semibold p-[15px] bg-[#D9D9D9] bg-opacity-[0.3] rounded-t-[15px]">{t("grievance.detail.general_info")}</h6>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.name")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word">{data?.created_by_details?.first_name + " " + data?.created_by_details?.last_name}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.date")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word uppercase">{moment(data?.created_at).format('DD MMM YYYY, hh:mm A')}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.status")}</p>
                                                <p className="text-xs !leading-tight flex items-center justify-center whitespace-nowrap h-[22px] px-[11px] text-white font-semibold bg-[#FCAB02] border border-[#EEE] rounded-full">{statusList?.[data?.status]}</p>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("auth.form.field.email")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{data?.created_by_details?.email}</h6>
                                            </div>

                                            {!!data?.created_by_details?.mobile && <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.phone")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-word">+91 {data?.created_by_details?.mobile}</h6>
                                            </div>}

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <div className="w-full flex items-center gap-[15px] justify-between">
                                                    <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.new_complaint.field.category")}</p>
                                                    {((user?.role === "Agent" || user?.role === "grievance_supervisor" || user?.role === "grievance_supervisor_admin") && data?.status !== "Resolved") &&
                                                        <button aria-label={t("aria_label.edit")} title={t("aria_label.edit")} className="min-w-[25px] h-[25px] flex items-center justify-center rounded-full hover:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] focus:shadow-[0px_14px_64px_rgba(24,39,75,0.12),0px_8px_22px_rgba(24,39,75,0.12)] hover:bg-white focus:bg-white dark:hover:bg-bgDarkDull dark:focus:bg-bgDarkDull border border-transparent focus:border-[#D7D8DD] hover:border-[#D7D8DD] dark:focus:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d] dark:hover:shadow-[0px_8px_22px_-6px_#ffffff40,0px_14px_64px_-4px_#ffffff0d]" onClick={() => setChangeCategory(true)}><EditCategory /></button>
                                                    }
                                                </div>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-anywhere">{data?.category_details}</h6>
                                            </div>
                                        </div>

                                        <div className="w-full xlsm:w-[calc(100%-210px)] xlx:w-[calc(100%-220px)] flex flex-col">
                                            <div className="flex items-center justify-between w-full gap-[15px] flex-wrap">
                                                <h6 className="text-[#313131] dark:text-textBlackDull font-semibold">{t("grievance.detail.title")}</h6>
                                                {(data?.status !== "Resolved" && data?.status !== "Refer_To_Customer" && user?.role !== "passenger" && user?.role !== "candidate" && user?.role !== "Agent" && user?.role !== "grievance_supervisor") && <div className={`flex xs:items-center w-full xs:w-fit toggle-submit-btn items-end justify-end rounded-[6px] ${stFocus && "shadow-[0_0_0_3px_#059db25e]"} hover:shadow-[0_0_0_3px_#059db25e]`}>
                                                    <div className="text-white text-sm !leading-snug font-bold h-[30px] w-fit bg-primary rounded-l-[6px] flex items-center justify-center whitespace-nowrap border-r border-white uppercase px-[12px]">{statusList?.[data?.status]}</div>

                                                    <Menu align="end" menuButton={<MenuButton onFocus={() => setStFocus(true)} onBlur={() => setStFocus(false)}>
                                                        <div className="h-[30px] min-w-[35px] flex items-center justify-center rounded-r-[6px] bg-primary">
                                                            <DrpArr />
                                                        </div>
                                                    </MenuButton>}>
                                                        {Array.isArray(menus) && menus?.map((i: any, idx: number) => (
                                                            <React.Fragment key={idx}>
                                                                {i?.key !== data?.status && <MenuItem onClick={() => handleStatusChange(i?.key)}><div className="text-blackDark text-sm !leading-snug font-semibold h-[30px] w-fit rounded-[5px] flex items-center justify-start px-[14px] whitespace-nowrap capitalize cursor-pointer">{i?.name}</div></MenuItem>}
                                                            </React.Fragment>
                                                        ))}
                                                    </Menu>
                                                </div>}

                                                {(user?.role === "grievance_supervisor" && data?.status !== "Resolved") &&
                                                    <SecondaryBtn title={t("grievance.home.banner.status.resolved")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => handleStatusChange("Resolved")} />
                                                }

                                                {(data?.status === "Refer_To_Customer" && data?.status !== "Resolved" && (user?.role === "passenger" || user?.role === "candidate")) &&
                                                    <SecondaryBtn title={t("grievance.refer_back_complaint.add_remarks")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => !!params?.lang ? navigate(type === "passenger" ? `/${params?.lang}/complaint/add-remarks/${params?.id}` : `/${params?.lang}/add-remarks/${params?.id}`) : navigate(type === "passenger" ? `/complaint/add-remarks/${params?.id}` : `/add-remarks/${params?.id}`)} />
                                                }

                                                {(user?.role === "Agent" && data?.status !== "Refer_To_Customer") &&
                                                    <SecondaryBtn title={t("grievance.home.banner.status.refer_back")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => handleStatusChange("Refer_To_Customer")} />
                                                }
                                            </div>

                                            <div className="mt-[8px] w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px]">
                                                <div className="flex items-center gap-[12px]">
                                                    <div className="h-[30px] min-w-[30px] flex items-center justify-center rounded-full text-[#015293] text-[12px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-tight uppercase">
                                                        {data?.created_by_details?.first_name?.charAt(0)}{data?.created_by_details?.last_name?.charAt(0)}
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-xs leading-snug text-blackDull dark:text-textBlackDull font-medium">{t("grievance.detail.from")}</p>
                                                        <h6 className="text-[#242555] dark:text-white font-semibold">{data?.created_by_details?.first_name + " " + data?.created_by_details?.last_name}</h6>
                                                    </div>
                                                </div>

                                                <h6 className="text-primaryText dark:text-white font-bold mb-[8px] mt-[12px] break-word">{data?.subject}
                                                </h6>

                                                <h6 className="text-blackDull dark:text-textBlackDull font-medium break-word">{data?.description}</h6>

                                                {!!data?.attachment_details?.aws_file_url && <Link to={data?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit mt-[17px] text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                    <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                    {data?.attachment_details?.name}.{data?.attachment_details?.type}
                                                </Link>}
                                            </div>

                                            {data?.ticket_activities?.length > 0 && <>
                                                <h6 className="text-[#313131] dark:text-textBlackDull font-semibold mt-[15px] mb-[12px]">{t("grievance.detail.act")}</h6>

                                                <div className="w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px] flex flex-col gap-[15px] xlx:gap-[25px]">
                                                    {Array?.isArray(data?.ticket_activities) && data?.ticket_activities?.map((item: any, index: number) => (
                                                        <div className="flex gap-[12px]" key={index}>
                                                            {!!item?.created_by ? <div className="h-[20px] min-w-[20px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase">
                                                                {item?.created_by_details?.first_name?.charAt(0)}{item?.created_by_details?.last_name?.charAt(0)}
                                                            </div> :
                                                                <ActivityLogo />
                                                            }

                                                            <div className="flex flex-col gap-[6px]">
                                                                {/* create */}
                                                                {item?.remark === "Complaint Posted" && <h6 className="text-blackDull dark:text-textBlackDull font-medium">
                                                                    {lang === "en" ?
                                                                        <>
                                                                            <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                            Created Complaint to
                                                                            <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                        </> :
                                                                        lang === "hi" ?
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                ने शिकायत
                                                                                <b> {statusList?.[item?.ticket_status]}</b> अनुभाग में बनाई।
                                                                            </> :
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                यांनी तक्रार
                                                                                <b> {statusList?.[item?.ticket_status]}</b> विभागात तयार केली.
                                                                            </>
                                                                    }


                                                                </h6>}

                                                                {/* assign to system */}
                                                                {item?.remark === "Complaint Assigned by System" && <h6 className="text-blackDull dark:text-textBlackDull font-medium">{lang === "en" ? <>Complaint Assigned to <b>{item?.assigned_department_details?.name}</b>.</> : lang === "hi" ? <>शिकायत को <b>{item?.assigned_department_details?.name}</b> को सौंपा गया।</> : <>तक्रार <b>{item?.assigned_department_details?.name}</b> कडे सोपवली गेली आहे.</>} </h6>}

                                                                {/* status change */}
                                                                {(item?.remark === "Complaint Status Updated" && item?.ticket_status !== "Resolved" && item?.ticket_status !== "Refer_To_Customer") && <h6 className="text-blackDull dark:text-textBlackDull font-medium">
                                                                    {lang === "en" ?
                                                                        <>
                                                                            <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                            Moved Complaint to
                                                                            <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                        </> :
                                                                        lang === "hi" ?
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                ने शिकायत को
                                                                                <b> {statusList?.[item?.ticket_status]}</b> करने वाले अनुभाग में स्थानांतरित किया।
                                                                            </> :
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                यांनी तक्रार
                                                                                <b> {statusList?.[item?.ticket_status]}</b> विभागात हलवली.
                                                                            </>
                                                                    }
                                                                </h6>}

                                                                {/* Resolved */}
                                                                {(item?.ticket_status === "Resolved" && item?.remark !== "Category changed") &&
                                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium"><b>{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> {lang === "en" ? "Marked Complaint Resolved." : lang === "hi" ? "ने शिकायत को समाधान किया हुआ चिह्नित किया।" : "यांनी तक्रार समाधानित म्हणून चिन्हांकित केली."}</h6>}

                                                                {/* Ctegory change */}
                                                                {item?.remark === "Category changed" &&
                                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium"><b>{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> {lang === "en" ? "changed the category of complaint." : lang === "hi" ? "ने शिकायत की श्रेणी बदल दी।" : "ने तक्रारीची श्रेणी बदलली."}</h6>}

                                                                {(!!data?.remarks && item?.ticket_status === "Resolved" && item?.remark !== "Category changed") && <h6 className="text-blackDull dark:text-textBlackDull font-medium break-word">{data?.remarks}</h6>}

                                                                {/* assign to user */}
                                                                {(item?.ticket_status !== "Resolved" && item?.remark !== "Complaint Posted" && item?.remark !== "Complaint Status Updated" && item?.remark !== "Complaint Assigned by System" && item?.remark !== "Category changed" && item?.ticket_status !== "Refer_To_Customer") &&
                                                                    <div className="flex flex-col gap-[6px]">
                                                                        {(!item?.remark?.includes("received response from customer")) && <h6 className="text-blackDull dark:text-textBlackDull font-medium"> {lang === "en" ? <><b>{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> Complaint Assigned to <b>{item?.assigned_department_details?.name}</b>.</> : lang === "hi" ? <><b>{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> ने शिकायत <b>{item?.assigned_department_details?.name}</b> को सौंपी।</> : <><b>{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> यांनी तक्रार <b>{item?.assigned_department_details?.name}</b> यांना सोपवली.</>}</h6>}
                                                                        <h6 className="text-blackDull dark:text-textBlackDull font-medium">{item?.remark}</h6>
                                                                    </div>
                                                                }

                                                                {/* Refer_To_Customer */}
                                                                {(item?.ticket_status === "Refer_To_Customer" && item?.remark !== "Category changed") && <div className="flex flex-col gap-[6px]">
                                                                    {(data?.created_by_details?.mobile?.toString() === item?.created_by_details?.mobile?.toString()) ? <>
                                                                        {!!item?.created_by && <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                            {lang === "en" ?
                                                                                <>
                                                                                    <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                    has reverted to the complaint.
                                                                                </> :
                                                                                lang === "hi" ?
                                                                                    <>
                                                                                        <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        ने शिकायत पर प्रतिक्रिया दी है।
                                                                                    </> :
                                                                                    <>
                                                                                        <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        ने तक्रारीस उत्तर दिले आहे.
                                                                                    </>
                                                                            }
                                                                        </h6>}
                                                                    </> :
                                                                        <>
                                                                            {/* {!!item?.created_by && <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                            {lang === "en" ?
                                                                                <>
                                                                                    <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                    Moved Complaint to
                                                                                    <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                                </> :
                                                                                lang === "hi" ?
                                                                                    <>
                                                                                        <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        ने शिकायत को
                                                                                        <b> {statusList?.[item?.ticket_status]}</b> करने वाले अनुभाग में स्थानांतरित किया।
                                                                                    </> :
                                                                                    <>
                                                                                        <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        यांनी तक्रार
                                                                                        <b> {statusList?.[item?.ticket_status]}</b> विभागात हलवली.
                                                                                    </>
                                                                            }
                                                                        </h6>} */}
                                                                            {!!item?.created_by && <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                                {lang === "en" ?
                                                                                    <>
                                                                                        <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        referred back the complaint.
                                                                                    </> :
                                                                                    lang === "hi" ?
                                                                                        <>
                                                                                            <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                            ने शिकायत को वापस भेज दिया।
                                                                                        </> :
                                                                                        <>
                                                                                            <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                            यांनी हुमांशूच्या तक्रारीचा संदर्भ दिला.
                                                                                        </>
                                                                                }
                                                                            </h6>}
                                                                        </>
                                                                    }
                                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium">{item?.remark}</h6>
                                                                </div>
                                                                }

                                                                {!!item?.attachment_details?.aws_file_url && <Link to={item?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] px-[9px] py-[5px] gap-[6px] flex  font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                    <LinkIcon className="xlx:min-w-[10px] xlx:w-[10px] xlx:h-[16px] min-w-[9px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                    {item?.attachment_details?.name}.{item?.attachment_details?.type}
                                                                </Link>}

                                                                <p className={`text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold opacity-[0.7] ${item?.attachment_details?.aws_file_url ? "mt-[4px]" : ""}`}><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(item?.created_at).format('DD MMM YYYY, hh:mm A')}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>}
                                        </div>
                                    </div>}
                                </>}
                        </div>
                    </div>
                </div >}

            {changeCategory &&
                <div className="fixed inset-0 z-[35] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] h-auto w-full sm:w-[400px] relative shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)]" ref={ref1}>
                            <div className="flex flex-col w-full gap-[15px] px-[20px] pt-[15px] pb-[8px]">
                                <div className="flex w-full justify-between items-center">
                                    <h4 className="text-primaryText dark:text-white font-semibold">{t("grievance.new_complaint.field.category")}</h4>

                                    <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" autoFocus onClick={() => setChangeCategory(false)}>
                                        <CloseBlack />
                                    </button>
                                </div>

                                <div className={`${focus ? "bg-white dark:bg-bgDarkDull" : "bg-[#F5F5F5] hover:bg-white dark:bg-bgDark dark:hover:bg-bgDarkDull"} border border-[#EFEFEF] dark:border-[#454545] h-[35px] xlx:h-[40px] px-[12px] xlx:px-[15px] flex items-center gap-[6px] xlx:gap-[9px] rounded-[11px] w-full`}>
                                    <TableSearch className="xlx:w-[18px] xlx:min-w-[18px] xlx:h-[18px] min-w-[15px] w-[15px] h-[15px] fill-primary" />
                                    <input type="text" className="text-blackDull dark:text-textBlackDull font-medium placeholder:text-opacity-[0.7] placeholder:text-blackDull dark:placeholder:text-textBlackDull text-sm leading-tight w-full bg-transparent" placeholder={t("aria_label.search")} autoComplete="off" value={search} onChange={(e: any) => setSearch(e.target.value)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} />
                                </div>
                            </div>

                            <div className="h-[280px] overflow-y-auto flex flex-col">
                                {categoryLoading ?
                                    <div className="w-full flex items-center justify-center py-[20px] mt-[-8px] h-full">
                                        <h5 className="text-primary font-black text-2xl max-w-[200px] text-center flex items-center uppercase gap-[2px]">Loading
                                            <div className="loader">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </h5>
                                    </div>
                                    :
                                    <>
                                        {categoryList?.length > 0 ? <div className="flex flex-col pb-[15px]">
                                            {Array.isArray(categoryList) && categoryList?.map((item: any, index: number) => (
                                                <button key={index} className={`${item?.value === data?.category ? "bg-[#DCFCFF] dark:bg-[#222424]" : "hover:bg-[#D9D9D9] dark:hover:bg-[#2224245e] dark:focus:bg-[#2224245e] hover:bg-opacity-[0.3] focus:bg-[#D9D9D9] focus:bg-opacity-[0.3]"} px-[20px] py-[10px] flex items-center gap-[15px] border-b border-[#DADADA] dark:border-[#454545]`} onClick={() => { if (item?.value !== data?.category) { setSelectCategory(item); setChangeCategoryCfm(true); } }}>
                                                    <h6 className="text-primaryText dark:text-white font-semibold text-left">{item?.label}</h6>
                                                </button>
                                            ))}
                                        </div> :
                                            <div className="p-[8px] w-full flex items-center justify-center h-full">
                                                <h6 className="text-blackDull dark:text-textBlackDull font-medium">{!!search ? t("passenger.lost_found.no_category") : t("passenger.lost_found.no_search")}</h6>
                                            </div>
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }

            {changeCategoryCfm &&
                <div className="fixed inset-0 z-[36] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] md:mx-auto bg-white dark:bg-bgDarkDull rounded-[10px] h-[195px] w-full sm:w-[310px] relative shadow-[0px_6px_14px_-6px_rgba(24,39,75,0.12),0px_10px_32px_-4px_rgba(24,39,75,0.10)] px-[20px] py-[15px]" ref={ref2}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />

                            <div className="flex w-full items-end justify-end absolute top-[15px] right-[20px]">
                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" autoFocus onClick={() => setChangeCategoryCfm(false)}>
                                    <CloseBlack />
                                </button>
                            </div>

                            <div className="flex flex-col items-center justify-end gap-[35px] h-full">
                                <h4 className="max-w-[220px] text-center font-semibold text-blackDark dark:text-textBlackDark">{t("grievance.detail.change_category")}</h4>
                                <div className="w-full flex items-center gap-[10px]">
                                    <SecondaryBtn title={t("website.yes")} css="px-[15px] font-semibold rounded-[6px] text-sm leading-snug w-full" onClick={handleChangeCategory} disabled={submit} />
                                    <SecondaryBtnLight title={t("recruitment.profile.field.btn.cancel")} css="px-[15px] font-semibold rounded-[6px] text-sm leading-snug dark:text-textBlackDark dark:border-textBlackDark dark:focus:shadow-[0_0_0_3px_#ffffff5e] dark:hover:shadow-[0_0_0_3px_#ffffff5e] w-full" onClick={() => setChangeCategoryCfm(false)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ComplaintDetail;